<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div class="mt-10" v-if="pmprb.length === 0">
      <v-alert type="error">
        Belum Ada <strong>PMPRB</strong> Untuk Saat Ini
      </v-alert>
    </div>

    <div class="mt-10 pb-10" v-else>
      <v-row>
        <v-col cols="12" md="4" v-for="item in pmprb" :key="item.pmprb_id">
          <v-card outlined height="500">
            <iframe
              :src="getPDF(item.pmprb_file)"
              type="application/pdf"
              height="100%"
              width="100%"
            ></iframe>
          </v-card>
          <div class="mt-5">
            <h3>{{ item.pmprb_nama }}</h3>
          </div>
          <v-divider></v-divider>
          <div>
            <span class="subtitle-2 grey--text">
              {{ item.pmprb_ket }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    pmprb: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Program",
        disabled: true,
        href: "",
      },
      {
        text: "PMPRB",
        disabled: false,
        href: "/program/pmprb",
      },
    ],
  }),

  mounted() {
    this.getData();
  },

  methods: {
    getPDF(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/pmprbFile/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/pmprbFile/pdf-default.pdf";
      }
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "pmprb")
        .then((res) => {
          this.pmprb = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
